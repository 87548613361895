.main-border-cards{
    display: flex;
    gap: 1%;
}
.main-border-container{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
}

.Invite{
    font-size: 17px;
}
.input-filled{
    margin-top: 1%;
}
.main-input-container{
    margin-top: 5%;
   /*  margin-left: 7%; */
}
.send-invite{
    margin-top: -12%;
    width: 20%;
    margin-left: 64%;
}
.table{
    font-size: 17px;
    // margin-left: 7%;    
    margin-top: -1%;
}
.main-table{
    display: flex;
    flex-direction: column;
}
.user-roles{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    
}
.main-container-border{
height: 159px;
width: 263px;
border: 1px solid rgb(236, 233, 233);
background-Color: #fff;
border-Radius: 11px;
margin-Top: 40px;
}

.mainBorderStyle {
    display: flex;
    justify-content: space-between;
  }
  
  .headingStyle {
    font-size: 16px;
    margin-right: 8%;
    margin-top: 9%;
    opacity: 0.7;
  }
  
.iconstyle{
    font-Size: 31px;
    margin-Top: 3%;
    margin-Left: 13%;
  }
  
  .secondaryHeadingStyle {
    font-size: 14px;
    margin-left: 12%;
  }
  
  .descriptionStyle {
    margin-top: 1%;
    opacity: 0.7;
    margin-left: 12%;
    font-size: 15px;
  }
  .border-setting-user{
   display: flex;
    gap: 10px;
    margin-Top: 4%;
  }
 .box-input-filleds{
    display: flex;
    gap: 10px;
 }
 .table-all-user{
    font-size: 20px;
    opacity: 0.7;
    font-weight: 550;
    margin-top: 2%;
 }
  @media screen and (max-width: 767px){
    .iconstyle {
        font-Size: 20px;
        margin-Top: 3%;
        margin-Left: 13%;
    }
    .headingStyle {
        font-size: 12px;
        margin-right: 8%;
        margin-top: 9%;
        opacity: 0.7;
    }
    .secondaryHeadingStyle {
        font-size: 11px;
        margin-left: 12%;
    }
    .descriptionStyle {
        margin-top: 1%;
        opacity: 0.7;
        margin-left: 12%;
        font-size: 10px;
    }
    .main-container-border {
        height: 119px;
        border: 1px solid rgb(236, 233, 233);
        background-Color: #fff;
        border-Radius: 11px;
        margin-Top: -11px;
        padding: 4px;
    }
    .border-setting-user {
        display: flex;
        gap: 10px;
        margin-Top: 12%;
    }
    .Invite {
        font-size: 12px;
    }
    .box-input-filleds{
        display: flex;
        flex-direction: column;
        gap: 10px;
     }
     .table-all-user{
        font-size: 15px;
        opacity: 0.7;
        font-weight: 550;
        margin-top: 2%;
     }
.Create-New-Role{
.css-sghohy-MuiButtonBase-root-MuiButton-root{
    margin-top: 4%;
    width: 44% !important;
    left: 54% !important;
    font-size: 9px !important;
}
}
.input-filled{
.css-sghohy-MuiButtonBase-root-MuiButton-root{
    width: 42% !important;
    font-size: 10px !important;
}


}

  }
  
