

.style-drawer{
    .ant-drawer-body{
        padding: 20px;
    }
    
}

.style-label{
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 6px;
}
.keyword-form{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    .keyword-input{
        width: calc(100% - 150px);
        height: 40px;
        margin-bottom: 0;
    }
    input{
        height: 40px;
    }

    button{
        max-width: 140px;
        margin-left: 10px;
        height: 40px;
    }
}
.keyword-btn{
    background: #10163a;
    color: #ffffff;
    min-width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 10px;
    padding: 7px 10px;
    display: inline-block;
    .MdDelete{
        margin-left: 10px;
        margin-right: 0;
    }
}
.sub-heading{
    font-size: 17px;
    font-weight: 500;
}
.button-container{
    display: flex;
    gap: 8px;
    margin-top: 4%;
    button{
        max-width: 250px;
        width: 100%;
        height: 40px;
        margin-top: -3%;
        border-radius: 8px 8px 0 0;   
        font-size: 15px;
        font-weight: 500;
    }
    button.active{
        background-color: #10163a;
        color: #ffffff;
    }
}

.main-trigger-name{
    .trigger{
        font-weight: 500;
        font-size: 16px;
    }
    Input{
        margin-top: 6px !important;
        width: 100% !important;
        height: 40px;
        max-width: 100%;
    }
}

.main-Initial-Con{
    margin: 15px 0;
    .Initial{
        font-weight: 500;
        font-size: 15px;
    }
    .main-input{
        display: flex;
        gap: 19px;
        margin-top: 3px;
        margin-bottom: 10px;
        input{
            width: calc(100% - 150px) !important;
            height: 40px;      
        }
        button{
            max-width: 150px;
            width: 100%;
            height: 40px;
        }
    }
}

.main-button-reg{
.button-reg{
    display: flex;
    gap: 20px;
    button{
        display: flex;
        justify-content: space-between; 
    }
}
button{
    margin-top: 1%;
}
}
.main-btn{
    .Bot-Response{
        font-weight: 500;
        font-size: 15px;
    }

TextArea{
    margin-top: 2% !important;
    width: 78%;
}
}
.main-radio-btn{
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    .Checkbox{
        font-weight: 800;
        margin-top: 1%;
    }
}

.main-Bot-Menu{
    margin-top: 4%;
    .Bot{
        font-weight: 500;
        font-size: 15px;
    }
    .main-input{
        display: flex;
        gap: 19px;
        margin-top: 3px;
        input{
            width: 78% !important;
            
        }
    }
}

.button-container-Update{
    display: flex;
    justify-content: end;
    margin: 20px 0;
    button{
        margin: 0 7px;
        max-width: 150px;
        width: 100%;
        height: 40px;
    }
}
.main-Intractivelist{
    display: flex;
    margin-top: 2%;
    font-size: 20px;
    gap: 7px;
}
.heading{
    font-size: 15px;
    margin-top: 1%;
    font-weight: 400; 
}
.heading-dis{
    font-size: 13px;
    margin-top: 10px;
}
#Testlist-cards{
    // width: 43%;
    margin-top: 2%;
}
.accordion{
    width: 100% !important;
    margin-top: 6% !important;
}
.Intractivelist-sub{
    font-weight: 500;
}
.text-form{
    margin: 15px 0;
}
.style-text-area{
   

}
.form-list-item{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .style-input{
        width: calc(100% - 10px);
        margin-bottom: 0;
        height: 35px;
        margin-right: 10px;
    }
    input{
        height: 35px;
    }
    .form-icon{
        margin-left: 5px;
        width: 35px;
        height: 35px;
        background: #10163a;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 5px;
        font-size: 16px;
        border: none;
        outline: none;
    }
}
.style-text-area{
    width: 100% !important;
    height: 100px !important;
    overflow-y: auto;
    resize: none !important;
}
.message-text{
    text-align: center;
    display: block;
    color: grey;
    font-size: 16px;
}
.sub-heading{
  text-align: center;
  font-weight: 700;
  margin-bottom: 0px;
}
.close-drawer{
    position: absolute;
    top: 3px;
    left: -37px;
    z-index: 1000;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
}
.upload-input{
    margin-bottom: 10px;
}

.style-select .ant-select{
    height: 45px;
}

.media-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #dbd8d8;
    border-radius: 10px;
    width: 100%;
    height: 250px;
  }
  
  .media-container img, .media-container video{
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 250px;
    height: 250px;
    object-fit: cover; /* Ensures the media fills the container without distortion */
    margin: 10px; /* Adjust margin as needed */
    object-position: top;
  }

  .media-container video{
    width: 100%;
  }


  .media-container audio,
  .media-container a {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensures the media fills the container without distortion */
    margin: 10px; /* Adjust margin as needed */
    object-position: top;
  }

  .media-container a {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline !important;
    display: inline-block;
  }

  .edit-media-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #10163a;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .style-input{
    height: 40px;
  }

  .form-section{
    display: none;
    &.active{
        display: block;
    }

  }

  .response-section{
    display: none;
    &.active{
        display: block;
    }

  }

  .loop-back-row{
    .style-select{
        width: 100%;
        margin-bottom: 0;
    }
    .style-input{
        margin-bottom: 0;
        height: 45px;
        input{
            height: 45px;
        }
    }
  }


  
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img,video, audio, a{
        width: 100%;
        height: auto;
        object-position: center;
        max-height: 100px;
    }
    img{
        object-fit: contain;
    }
    audio{
        height: 40px;
    }
    a{
        display: block;
        text-align: center;
    }
  }