.campaigns-parent {
  width: 100%;

  .start-div {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .heading {
      font-size: 20px;
      font-weight: 550;
      opacity: .7;
    }
    .sub-heading {
      font-size: 12px;
      opacity: .7;
    }
  }
}
.info-box {
  padding:16px;
  margin-top: 2%;
  background: rgba(127,255,0, .1);
  border-radius: 10px;
  .info-box-first {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.connect-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 30px;
  gap:10px;
  background: rgb(249, 250, 251);
  border-radius: 10px;
  margin-top: 30px;
}