.tab-container {
  :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-top >.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-bottom >.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-top >div>.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-bottom >div>.ant-tabs-nav {
    margin: 0 !important;
  }
}


.chat-main-container {
    display: flex;
    margin-top: 10px;
    gap:10px;
    height: calc(100vh - 10.8rem);
    .chat-left-panel {
      height: 100%;
      width: 0px;
      position: relative;
      overflow: inherit;
    transition: width 0.5s ease;
    .allconversation-main{
      display: none;
    }
    .whatsapp-allconversation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: none;
  }
 
 
  .Chats-conversationsProfile {
    font-weight: 550;
    width: 100%;
    opacity: 0.7;
    text-align: right;
    display: none;
  }
      
      
      .fab-icon {
        position: absolute;
        bottom: 60px;
        right: 30px;
      }
      .cards-box {
        height: 100%;
        .cards-box {
          .no-search-found {
            width: fit-content;
            margin: auto;
          }
        }
        .new-message-pop {
          border-radius: 10px;
          padding: 10px;
          background: rgba(4,200,200, .11);
          display: flex;
          align-items: center;
          gap: 10px;
          .heading {
            font-weight: 600;
            font-size: 14px;
            color:rgba(4,200,200, 1);
          }
          .description {
            font-weight: 600;
            font-size: 11px;
            opacity: .7;
          }
          .circle {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background: rgba(4,200,200, .4);
            display: flex;
            justify-content: center;
            align-items: end;
            p {
              color:white;
            }
          }
        }
      }
    }
    .collapse{
      width: 23%;
      transition: 1s ease;
      display: block;
      position: relative;
      .allconversation-main{
        display: block;
        overflow: hidden;
      }
      
    }
    .chat-right-panel {
      .cards-box {
        .heading {
          font-size: 20px;
          font-weight: 550;
          text-align: center;
          margin-top: 10px;
          color: #252F4A;
        }
        .heading:hover {
          color: #04c8c8;
        }
      }
    }
  }
  
  .messages-container {
    height:calc(100vh - 19.6rem);
    overflow-y:scroll;
    padding:10px;
  }
  .messages-container::-webkit-scrollbar {
    display: none;
  }
  
  .messages-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .all-conversation::-webkit-scrollbar {
    display: none;
  }
  
  .all-conversation {
    width: 100%;
    max-width: 360px;
    height: calc(100vh - 19rem);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .allConversationMenu {
    border-radius: 10px;
    padding:6px;
  }
  .allConversationMenu:hover {
    background: rgba(0,0,0,.05);
  }
  
  .inbox-tabs {
    display:flex;
    align-items:center;
    cursor: pointer;
    color: rgba(0,0,0,.6);
    padding: 5px;
    border-radius: 8px;
    justify-content: space-between;
    font-weight: 500;
  }
  .inbox-main {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    width: 18%;
    border-right: 1px solid;
    border-right-color: rgba(17, 24, 39, .2);
    font-size: 14px;
  }
  .inbox-tabs:hover {
    background: #F4F7FE;
  }
  .inbox-parent {
    width:81%;
    height:calc(100vh - 4rem);
    display: flex;
  }
  .inbox-center-main {
    height: 100%;
    border-right-color: rgba(17, 24, 39, 0.2);
  }
  .new-message {
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    background: rgb(226, 232, 240);
    border-radius: 10px;
  
    p {
      margin: 0;
  
    }
  }
  .inbox-right-section {
    width: 48%;
  }
  .right-input {
    display: flex;
    justify-content: space-between;
    div {
      width: 48%;
      FormControl {
        width: 100%;
      }
    }
  }
  .right-section-heading {
    text-align: center;
    color:rgb(37, 99, 235);
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 10px;
  }
  
  .css-hhvo2v-MuiListItem-root {
    padding: 0 !important;
  }
  
  .contact_info_icons {
    color:rgba(0, 0, 0, 0.3)
  }
  
  .whatsapp-allconversation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
  }
  .Chats-conversationsProfile{
    font-weight: 550;
    width: 100%;
    opacity: 0.7;
    text-align: right;
    display: none;
  }
  .collapse .Chats-conversationsProfile{
    font-weight: 550;
    width: 100%;
    opacity: 0.7;
    text-align: right;
    display: block;
  }
  .collapse .whatsapp-allconversation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .chat-left-panel .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    display: none !important;
  }
  .collapse .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    display: block !important;
  }
  
  .chat-left-panel:hover {
    height: 100%;
    width: 23%;
    position: relative;
    transition: 1s ease;
    .allconversation-main{
      display: block;
    }

   
    .collapse 
    .whatsapp-allconversation {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .Chats-conversationsProfile {
    font-weight: 550;
    width: 100%;
    opacity: 0.7;
    text-align: right;
    display: block;
  }
  
   
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      display: block !important;
    }
    
  }
  .collapse + .chat-right-panel {
    width: 76%;
    transition: width 0.3s ease;
  }
  .chat-left-panel:hover + .chat-right-panel {
    width: 76%;
    transition: width 0.3s ease;
  }
  
  .chat-right-panel {
    flex:1;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95.5%;
    transition: width 0.3s ease;
  }
  
  @media screen and (max-width: 767px){
  
    .inbox-rules-p {
      opacity: 0.8;
      text-align: center;
      font-size: 10px;
  }
  
  .bot-border-rules p {
    text-align: center;
    font-weight: 400;
    opacity: 0.7;
    margin: 4px;
    font-size: 8px;
  }
  
  }

.all-conversation-conatiner {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height:calc(100vh - 20rem);
  overflow-y:auto;
}
.all-conversation-conatiner::-webkit-scrollbar {
  display: none;
}