.dashboard-container {
    height: 100vh;
    margin-left: 74px;
}
.aside {
    width: 74px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #3f4faa;
    gap: 2px;
    height: 100%;
    //position: absolute;
    z-index: 400;
    background-color: #10163a;
    transition: .2s;
}

.aside-fixed {
    width: 290px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #3f4faa;
    // gap: 2px;
    height: 100%;
    z-index: 22;
    background-color: #10163a !important;
    transition: .2s;
}

.aside:hover {
    width: 290px;
    .leftSectionLabel {
        display: block;
        position: absolute;
        margin-left: 51px;
    }
    .leftTabs {
        //width: 90%;
    }
}

.leftSectionLabel {
    font-size:16px;
    margin-left:30px;
    color:#B5B5C3;
    display:none;
    cursor: pointer;
}

.leftTabs {
    padding:10px;
    margin-left:16px;
    display:flex;
    align-items:center;
    border-radius:4px;
}

.aside-icons-logo {
    width: 40px;
}
.aside-icons {
    color: rgba(255,255,255,.8);
    cursor:pointer;
}
.aside-icons:hover {
    background: rgba(0,0,0,.03);
}
.aside-profile-pic {
    height: 30px;
    width: 30px;
    border-radius: 6px;
    background-size: cover;
}
.left-section-container {
    height: 100%;
}
.floating-btn-aside {
    height:34px;
    width:34px;
    position:absolute;
    transition:0.5s;
    background:#04c8c8;
    border-radius:8px;
    border-width:0;
    margin-top: 40%;
}
.main-aside {
    overflow:hidden;
    transition:0.5s;
    border-right: 1px solid #10163a;
    margin-left: 74px;
    background-color: #10163a !important;
}
.floating-btn-aside-icon {
    color:white;
    height:24px;
    width:24px;
}
.inbox-aside-menu {
    display:flex;
    justify-content:space-between;
    width:90%;
    color:#78829D;
    margin:auto;
    border-radius: 6px;
}
.inbox-aside-menu:hover {
    background: rgba(0, 0, 0, 0.03);
}
.search-drawer {
    padding: 20px;
}
.drawer-tabs {
    display: flex;
    gap:4px;
    margin-top: 4%;
    cursor: pointer;
    .tab {
        padding:2px 6px 2px 6px;
        opacity: .8;
        border-radius: 2px;
    }
    .tab:hover {
        background: rgba(0,0,0,.03);
    }
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    margin: -1px !important;
}

.aside-fixed .lock-icon {
    display: block; 
}

.aside .lock-icon {
    display: none; 
}

.aside:hover .lock-icon {
    display: block; 
}
.leftTabs:hover .leftSectionLabel {
    display: block;
    
}
.aside-fixed .leftTabs .leftSectionLabel {
    //width: 214px;

}

.aside-fixed .leftTabs .leftSectionLabel {
    display: block;
}


@media only screen and (max-width: 767px){
    .aside {
        width: 74px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #F1F1F2;
        gap: 7px;
        height: 100%;
        z-index: 22;
        background-color: white;
        transition: .2s;
        position: fixed;
    }
    .main-aside {
        
        transition:0.5s;
        border-right: 1px solid #F1F1F2;
        margin-left: 74px;
    }

    .aside-fixed {
        width: 388px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #F1F1F2;
        height: 100%;
        z-index: 400;
        background-color: white;
        transition: 0.2s;
        overflow-x: hidden;
        gap: 5px;
    }
 
   
}