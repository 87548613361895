.teamDescriptionTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.teamHeading {
  font-size: 22px;
  font-weight: 550;
  opacity: .7;
  display: flex;
  gap: 10px;
  align-items: center;

}
.header_input {
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 20px !important;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

}
@media screen and (max-width: 767px){
  :where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper {
    clear: both;
    margin-top: 9% !important;

}
:where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
  font-size: 8px !important;
}
:where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
  border-start-end-radius: 8px;
  font-size: 8px !important;
}
}