*{
 margin: 0;
 padding: 0;
 font-family: Inter;
}
.cards-box {
 box-shadow: 0 10px 35px 0 rgba(56, 71, 109, 0.075);
 border-radius: 10px;
 background: white;
 padding: 20px;
}
.synctic-module-def {
 .icon {
  height: 40px;
  width: 40px;
  color: rgb(37, 99, 235)
 }
 .heading {
  font-size: 20px;
  font-weight: 550;
  margin-top: 10px;
  opacity: .7;
 }
 .heading:hover {
  color: #04c8c8;
 }
 .sub-heading {
  opacity: .7;
 }
}

.synctic-module-container {
 background: #F2F6F9;
 padding: 10px;
 overflow-y: scroll;
 width: 100%;
 height: calc(-5rem + 100vh);
}
@media screen and (max-width: 767px) {
    .cards-box {
        box-shadow: 0 10px 35px 0 rgba(56, 71, 109, 0.075);
        border-radius: 10px;
        background: white;
        padding: 20px;
        margin-top: 13%;
    }
    .synctic-module-def .heading {
        font-size: 14px;
        font-weight: 550;
        margin-top: 10px;
        opacity: 0.7;
    }
    .synctic-module-def .sub-heading {
        opacity: 0.7;
        font-size: 10px;
    }
    .synctic-module-def {
        .icon {
         height: 20px;
         width: 20px;
         color: rgb(37, 99, 235)
        }
    }
  }